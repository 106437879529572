export const localizationRussian = {
  about_us: "О Нас",
  about: "Основное",
  how_it_work: "Как пользоваться",
  how_it_works: "Как пользоваться",
  contacts_us: "Связаться с нами",
  faq: "ЧЗВ",
  dashboard: "Панель приборов",
  log_out: "Выйти",
  product: "Продукт",
  terms_of_use: "Условия эксплуатации",
  privacy_policy: "Политика конфиденциальности",
  mobile_app: "Мобильное приложение",
  management_tool: "Инструменты управления ",
  bar_control: "Bar Control",
  owner_operator_company_name: "Название компании",
  e_mail: "Электронная почта",
  phone_number: "Номер телефона",
  comment: "Комментарий",
  request_a_callback: "Запросить обратную связь",
  frequently_asked_questions: "Frequently Asked Questions",
  branch_management: "Управление филиалом",
  branches: "Ветви",
  request_for_new_branch: "Запрос на новую ветку",
  manager_invitations: "Приглашения менеджера",
  managers: "Менеджеры",
  barmen_list: "Список барменов",
  goods_type: "Тип товаров",
  goods: "Товары",
  inventories: "Инвентаризации",
  iiko: "Iiko",
  integration: "Интеграция",
  navigation_menu: "Меню навигации",
  items: "Список",
  name: "Название",
  phone: "Номер телефона",
  email: "Электронная почта",
  thank_you_email_is_verified: "Спасибо.Электронная почта подтверждена",
  address: "Адрес",
  latitude: "Широта",
  longitude: "Долгота",
  actions: "Действия",
  action: "Действия",
  active: "Активный",
  not_active: "not active",
  branch_requests: "Запросы филиалов",
  create: "Создать",
  requested_branches_count: "Количество запрошенных ветвей",
  message: "Сообщение",
  status: "Статус",
  barcode: "Штрих код",
  full_bottle: "Полный вес",
  bottle_count: "Количество бутылок",
  left_over_ml: "Left over",
  created_at: "Создано в",
  updated_at: "Обновлено",
  deleted_at: "Удалено",
  count_of_bottles: "Кол-во бутылок",
  left_over: "Остаток",
  save: "Сохранить",
  save_filter: "Save filter",
  sign_in: "Войти",
  sign_in_subtitle:
    "Если у вас нет зарегистрированной учетной записи, вы можете",
  sign_up_here: "Подпишите здесь",
  sign_up: "Подписаться",
  sign_up_subtitle: "Если у вас уже есть аккаунт",
  sign_in_here: "Войти здесь",
  role: "Роль",
  branch_name: "Название филиала",
  created: "Созданный",
  full_name: "Full Name",
  company_name: "Название компании",
  company_business_address: "Юридический адрес компании",
  branch_count: "Количество Филиaлов",
  company_email: "Электронная почта компании",
  tax_code: "Налоговый код",
  registration_number: "Регистрационный код (необязательнo)",
  goods_types: "Тип товаров",
  weight_full: "Полный  вес",
  weight_full_g: "Полный  вес (г)",
  weight_empty: "Пустой вес",
  weight_empty_g: "Пустой вес (г)",
  select_branch: "Выберите филиал",
  type: "Вид",
  in_progress: " В процессе",
  in_progress_status: "В Процессе",
  complete: "Завершенный",
  branch: "Ветвь",
  order: "Заказ",
  newest: "Новейшие",
  oldest: "Старые",
  email_address: "Электронная почта",
  fill_the_address: "Fill the address",
  search_terms: "Search terms...",
  search_orders: "Search orders...",
  password: "Пароль",
  repeat_password: "Подтвердите пароль",
  token_is_expired: "Срок действия токена истек",
  goods_name: "Название товара",
  restaurant_name: "Название ресторана",
  scales: "Весы",
  all_statuses: "All statuses",
  pending: "В ожидании",
  completed: "Завершенный",
  rejected: "Rejected",
  processing: "Processing",
  cancelled: "Cancelled",
  branches_count: "Количество филиалов",
  date: "Дата",
  details: "Подробности",
  goods_requests: "Запросы товаров ",
  good_detail: "Good detail",
  s_n: "S/N",
  manufacturer: "Производитель",
  production_date: "Дата производсва",
  mac: "Мак",
  is_active: "Активный",
  change_user_status: "Are you sure you want to change the user status?",
  reject: "Отклонить",
  accept: "Принять",
  text: "Текст",
  contact: "Contact",
  view: "Посмотреть",
  image: "Изображение",
  slides: "Slides",
  close_drawer: "Close drawer",
  bamburgh_pro: "Bamburgh React Admin Dashboard with Material-UI PRO",
  collapse_sidebar: "Свернуть",
  expand_sidebar: "Развернуть",
  cancel: "Отменить",
  want_to_reject: "Вы уверены ,что хотите отклонить?",
  want_to_accept: "Вы уверены ,что хотите принять?",
  confirmed: "Подтверждено",
  analytics: "Analytics",
  statistics: "Statistics",
  overview: "Overview",
  back_to_dashboard: "Back to dashboard",
  chat: "Chat",
  calendar: "Calendar",
  file_manager: "File Manager",
  projects: "Projects",
  profile: "Профиль",
  profile_completion: "Profile completion",
  monitoring_dashboard: "Monitoring Dashboard",
  analytics_dashboard: "Analytics Dashboard",
  statistics_dashboard: "Statistics Dashboard",
  view_profile: "View profile",
  web_content: "Веб контент",
  users: "Пользователи",
  messages: "Сообщения",
  terms_and_conditions: "Общие положения и условия",
  main_slider: "Основная панель",
  benefit_section: "раздел польза",
  steps_section: "Секциая шагов",
  image_section: "Раздел изображения",
  items_section: "Раздел наименование",
  clients_section: "Клиентцкий раздел",
  mobile_section: "Мобильная секция",
  management_section: "Раздел управления",
  contact_section: "Раздел контактов",
  footer_section: "Нижний раздел",
  learn_more: "Learn more",
  accountant_profile: "This is an accountant profile",
  logout: "Logout",
  tasks: "Tasks",
  reports: "Reports",
  stats: "Stats",
  server_stats: "Server Stats",
  view_more_items: "View more items",
  sales_target: "Today's sales target",
  daily_statistics: "Daily statistics regarding your sales targets",
  generate_report: "Generate report",
  view_complete_report: "View complete report",
  mistaken_idea: "But I must explain to you how all this mistaken idea.",
  wonderful_serenity: "A wonderful serenity has taken possession.",
  presidential_scandal: `Bill Clinton's presidential scandal makes it
  online.`,
  java_exam_day: "Java exam day",
  business_investor_meeting: "Business investor meeting",
  new: "New",
  graphical_browser: `Mosaic, the first graphical browser, is
                                introduced to the average consumer.`,
  own_image: "Who formed us in his own image, and the breath.",
  dummy_text_acting: "This is a dummy text acting ...",
  files_ready: "Files ready",
  add: "Add",
  decrease: "decrease",
  cards_examples: "Cards examples",
  form_wizards: "Form wizards",
  pricing_tables: "Pricing tables",
  dashboards: "Dashboards",
  there_are: "There are",
  multiple: "multiple",
  dashboard_layouts_available: "dashboard layouts available!",
  helpdesk: "Helpdesk",
  crm_ui: "CRM UI",
  customers: "Customers",
  delete: "Удалить",
  menu_box: "This menu box is scrollable (sm)",
  services: "Services",
  others: "Others",
  components: "Components",
  view_details: "Просмотр",
  web_developer: "Web developer W",
  javascript: "Javascript",
  angular: "Angular",
  incapable_of_drawing: `I should be incapable of drawing a single stroke at the
                  present moment; and yet I feel that I never was a greater
                  artist than now.`,
  team_members: "Team members",
  view_complete_profile: "View complete profile",
  wonderful_serenity_has_possession: "Wonderful serenity has possession",
  please_wait_while_load: "Please wait while we load the live preview examples",
  preview_instance: `This live preview instance can be slower than a real
                                production build!`,
  add_new_entry: "Add new entry",
  stay_up_to_date: "Stay up to date",
  subscribe_to_stay_up_to_date:
    "Subscribe to stay up to date with our latest releases and updates!",
  results_per_page: "results per page",
  ascending: "Ascending",
  descending: "Descending",
  order_details: "Order details",
  source: "Source",
  order_id: "Order ID",
  balance: "Balance",
  amount: "Amount",
  fiat_deposit: "Fiat deposit",
  bank_account: "Bank Account",
  deposit: "Deposit",
  paypal_withdraw: "Paypal withdraw",
  crypto_wallet: "Crypto Wallet",
  my_bitcoin_wallet: "My Bitcoin Wallet",
  withdraw: "Withdraw",
  current_month: "Current month",
  your_daily_tasks_list: "Your daily tasks list",
  finish_tasks_for_today: "Finish tasks for today",
  submit_report: "Submit Report",
  get_in_touch: "Get in touch",
  MySpace_network: "MySpace becomes the most popular social network.",
  pending_actions: "You have pending actions to take care of.",
  total_sales: "Total Sales",
  total_performance: "Total performance for selected period",
  world_among_the_stalks: "World among the stalks",
  deploy_new_website: "Deploy new website",
  comments_available: "There are 653 new comments available!",
  latest_sales: "Latest sales",
  increase: "Increase",
  last_year: "Last year",
  new_messages: "new messages",
  world_wide_web: `The World Wide Web goes live with its first web
                            page.`,
  clinton_presidential: "Bill Clinton's presidential scandal makes it online.",
  first_graphical_browser: `Mosaic, the first graphical browser, is introduced
                            to the average consumer.`,
  learning_round_table_gathering: "Learning round table gathering",
  incoming_messages: "Incoming messages",
  results: "Results",
  all_orders: "All Orders",
  all_types: "All types",
  buy_crypto: "Buy Crypto",
  sell_crypto: "Sell Crypto",
  transfer_funds: "Transfer Funds",
  filter_results: "Filter results",
  delete_entry: "Are you sure you want to delete this entry?",
  cannot_undo_operation: "You cannot undo this operation.",
  enable_disable: "Включить/Выключить",
  login: "Login",
  fill_fields: "Fill in the fields below to login to your account",
  or_sign_in_with_credentials: "or sign in with credentials",
  serial_number: "Серийный номер",
  mac_address: "Мак адрес",
  weight_empty_bigger_full:
    "Weight empty can't be bigger or equal than weight full",
  full_bottle_weight_gram: "Вес полной бутылки (грамм)",
  full_bottle_weight_kilogram: "Вес полной бутылки (килограмм)",
  empty_bottle_weight_gram: "Вес пустой бутылки (грамм)",
  empty_bottle_weight_kilogram: "Вес пустой бутылки (килограмм)",
  volume_milliliter: "Объем (миллилитр)",
  volume_liter: "Объем (литр)",
  crafted_with: "crafted with",
  by: "by",
  monday: "Monday",
  tuesday: "Tuesday",
  wednesday: "Wednesday",
  thursday: "Thursday",
  friday: "Friday",
  saturday: "Saturday",
  sunday: "Sunday",
  last_week: "Last week",
  last_month: "Last month",
  last_quarter: "Last quarter",
  notifications: "Notifications",
  you_have: "У вас есть",
  timeline: "Timeline",
  blinded_by_desire: "Blinded by desire",
  mega_menu: "Mega menu",
  real_estate: "Real Estate",
  applications: "Applications",
  contacts: "Contacts",
  layouts: "Layouts",
  view_documentation: "View documentation",
  view_product_details: "View product details",
  Powerful_admin_dashboard:
    "Powerful admin dashboard templates & ui kits that are easy to use and customize.",
  user: "User",
  city: "City",
  home: "Главная",
  good_types: "Виды товаров",
  good_type: "Тип товаров",
  remember_me: "Запомните меня",
  crypto_components: "Crypto Components",
  buttons: "Buttons",
  first_ever_iPod: "First ever iPod launches.",
  latest_reports_available: "Latest reports available",
  browse: "Выбирайте файл",
  file_for_upload: "Файл для выгрузкы",
  file_will_be_uploaded: "file will be uploaded",
  chosen_file_appear: "Здесь появится файл!",
  all_files_will_be_uploaded: "All files will be uploaded!",
  some_files_will_be_rejected: "Some files will be rejected!",
  drag_and_drop: "Переместите",
  file: "файл",
  files: "файл",
  here: "",
  or: "или",
  jpg_png_images: "(изображения jpg/png)",
  for_upload: "for upload",
  chosen: "Chosen",
  will_appear_here: "will appear here!",
  success: "success",
  files_click: "files! click",
  tables: "Tables",
  change_password: "Изменить пароль",
  title: "Заголовок",
  inputs: "Inputs",
  question: "Вопрос",
  answer: "Ответ",
  secondary: "Подзаголовок",
  play_market: "Play market",
  app_store: "App store",
  description: "Описание",
  average_density: "Средняя плотность",
  density: "Плотность",
  volume: "Объем",
  litre: "литр",
  all_fields_are_required: "Все поля обязательны для заполнения",
  forgot_password: "Забыли Ваш пароль?",
  email_is_not_valid: "Электронный адрес почты недействителен.",
  password_characters:
    "Пароль недействителен должен содержать 6 или более символов.",
  check_your_email: "Проверьте свою электронную почту.",
  reset: "Сброс",
  settings: "Настройки",
  inventory: "Инвентаризация",
  empty_branches: "Пустые филиалы, попробуйте еще раз.",
  in_process: "в процессе",
  bar_title: "Название бара",
  no_inventories: "Еще нет инвентаризации. Хотите создать инвентаризацию??",
  complete_inventory: "Завершить инвентаризацию",
  export_inventory: "Экспортировать инвентаризацию",
  export_as: "Экспортировать как",
  edit: "Редактировать",
  edit_profile: "Редактировать профиль",
  choose_from_photo_library: "Выберите из галереи",
  take_from_camera: "Фотографировать",
  user_info_successfully_updated: "Информация о пользователе успешно обновлена",
  first_name_empty: "Имя не может быть пустым",
  last_name_empty: "Фамилия не может быть пустой",
  title_empty: "Заголовок не может быть пустым",
  edit_profile_photo: "Редактировать фото профиля",
  first_name: "Имя",
  last_name: "Фамилия",
  change: "Изменить",
  current_password: "Текущий пароль",
  new_password: "Новый пароль",
  confirm_password: "Подтвердите пароль",
  something_went_wrong: "Ошибка",
  litrage: "Литраж",
  measuring_time: "Время измерения",
  time: "Время",
  bottles_qty: "Количество бутылок",
  delete_inventory: "Удалить инвентаризацию",
  edit_inventory: "Редактировать инвентаризацию",
  leftover: "Остаток",
  delete_the_inventory: "Вы уверены, что хотите удалить инвентаризацию?",
  bottles: "Бутылки",
  bottles_count: "Количество бутылок",
  bottle_weight: "Вес бутылки (грамм)",
  consider_bottle_barcode:
    "Штрих-код не просканирована, пожалуйста просканируйте штрих-код.",
  valid_bottles_count: "Введите допустимое количество бутылки.",
  valid_bottle_weight: "Введите действительный вес бутылки.",
  weight_empty_weight: "Вес бутылки не может быть меньше веса пустой бутылки.",
  weight_full_weight: "Вес бутылки не может быть больше веса полной бутылки.",
  good_not_exists: "Напиток не существует",
  barcode_doesnt_exist:
    "Товара со штрих-кодом не существует. Создать новый товар?",
  inventory_result_successfully: "Результат взвешивания был успешно добавлен.",
  open_bottle_weight: "Вес открытой бутылки (грамм)",
  open_bottle_weight_kg: "Вес открытой бутылки (килограмм)",
  quantity: "Количество",
  pairing_status: "Статус подключения",
  connected: "Подключено",
  not_connected: "Нет подключения",
  bluetooth_is_off: "Bluetooth выключен",
  please_turn_on_bluetooth: "Пожалуйста, включите Bluetooth",
  scan_barcode: "Просканируйте штрих-код",
  turn_on_bluetooth: "Включите Bluetooth",
  connect_to_scale: "Подключиться к весам",
  start_scaling: "Начать взвешивание",
  check_the_scale: "Проверить весы",
  permission_not_allowed: "Нет разрешения",
  allow_now: "Разрешить сейчас?",
  yes: "Да",
  no: "Нет",
  sure_complete_inventory: "Вы уверены, что хотите завершить инвентаризацию?",
  litres: "л",
  milliliters: "мл",
  no_scaling_yet: "Нет данных по данной инвентаризации",
  search: "Поиск",
  search_goods_name: "Поиск по названию продукта",
  bottle_volume: "Объем бутылки",
  full_bottle_weight_kg: "Вес полной бутылки (килограмм)",
  empty_bottle_weight_kg: "Вес пустой бутылки (килограмм)",
  select_type: "Выберите тип",
  density_g_cm: "Плотность(г/см³)",
  density_kg_m: "Плотность(кг/м³)",
  create_good: "Создать товар",
  create_good_success: "Товар был успешно создан.",
  update_good_success: "Товар был успешно редактирован.",
  good_already_exist: "Товар уже существует",
  good: "Товар",
  empty_current_password: "Текущий пароль не может быть пустым",
  empty_new_password: "Новый пароль не может быть пустым",
  empty_confirm_password: "Подтверждение пароля не может быть пустым",
  passwords_doesnt_match: "Пароли не совпадают",
  password_updated: "Пароль успешно изменен",
  on: "Включить",
  off: "Выключить",
  autosave: "Автосохранение",
  weight: "Вес",
  language: "Язык",
  milliliter: "миллилитр",
  connect_scale_bluetooth: "Подключитесь к весам через Bluetooth",
  Place_bottle_scale: "Поставьте бутылку на весы",
  scan_bottle_barcode: "Просканируйте штрих-код",
  report_scaling_result: "Отчет о результате взвешивания",
  ok: "Ok",
  edited: "Отредактировано",
  deleted: "Удалено",
  unmodified: "Неизмененный",
  add_full_bottles: "Полные бутылки",
  disconnected: "Отключен",
  g: "гр",
  kg: "кг",
  g_cm: "г/см³",
  kg_m: "кг/м³",
  are_you_sure: "Вы уверены ?",
  account_activation: "Активация аккаунта",
  enter_password_account: "Введите пароль для активации вашего аккаунта",
  email_isnt_verified: "Электронная почта не подтверждена,произошла ошибка ",
  can: "Вы можете",
  agree: "Я согласен с сайтом",
  terms: "условия и положения",
  and: "а также",
  thank_you_register: "Спасибо за регистрацию!",
  successfully_sign_up:
    'Вы успешно зарегистрировались. Пожалуйста, проверьте свою электронную почту, чтобы подтвердить учетную запись"',
  help_reset_password:
    "Мы поможем вам сбросить его и вернуться в нужное русло.",
  reset_password: "Сбросит пароль",
  have_account:
    "Если у вас есть учетная запись, мы отправим вам ссылку для сброса по электронной почте.",
  download: "Скачать",
  for_trackers: "Для подписчиков",
  faq_title: "Часто задаваемые ворпосы",
  deactivated: "Деактивировано",
  are_you_sure_you_want_to: "Вы уверены, что хотите ",
  requested: "Запрошено",
  canceled: "Отменено",
  accepted: "Принято",
  expired: "Просрочено",
  action_buttons: "Действие",
  deactivate: "Деактивировать",
  activate: "Активировать",
  export_pdf: "Экспорт в PDF",
  export_excel: " Экспорт в Excel",
  measurement: "Единица измерения",
  export_extended_pdf: "Экспортировать в расширенный PDF",
  export_extended_excel: "Экспортировать в расширенный Excel",
  settings_title: "Изменение настроек измерения",
  gram: "грамм",
  kilogram: "килограмм",
  liter: "литр",
  adjust_volume: "Отрегулируйте объем",
  adjust_weight: "Отрегулировать вес",
  measurement_unit: "Единицы измерения",
  sync_message:
    "Чтобы просмотреть ваши товары, вам необходимо синхронизировать данные о ваших товарах.",
  unresolved: "Не соответствует",
  solved: "Соответствует",
  sync: "Синхронизация",
  all: "Все",
  iiko_products: "Товары Iiko",
  semi_manufactures: "Заготовки",
  search_iiko: "Найти товар Iiko",
  search_good: "Найти товар BarControl",
  merge: "Объединить",
  select_product: "Пожалуйста, выберите товар для объединения",
  unit: "Единица измерения",
  send: "Отправлять",
  confirm: "Подтверждать",
  choose_store: "Пожалуйста выберите склад",
  prepare: "Подготовить",
  inventory_prepare: "Подготовьте инвентаризацию",
  store: "Склады",
  send_warning_msg:
    "Пожалуйста,сопоставьте товары вручную на странице интеграции",
  resolve: "Решать",
  unresolved_products: "не соответствующих товаров BarControl.",
  unresolved_by_unit: "Не соответствует по единице измерения",
  manually_resolved: "Сопоставлено вручную",
  resolve_goods: "Сопоставить товары",
  unresolved_goods: "не соответствующих товаров Iiko.",
  unresolved_unit_goods: "не соответствующих товаров по единице измерения.",
  successfully_sync: "Вы успешно синхронизировались с iiko.",
  credential_warning: "Введите учетные данные в ",
  credentials_settings: "настройках",
  continue_sync: ", чтобы продолжить процесс синхронизации с iiko.",
  you_have_unresolved: "У вас есть",
  back: "Назад",
  history_of_scales: "История",
  full: "Полная",
  open: "Открыта",
  after: "До",
  before: "После",
  iiko_settings_success_msg: "Успешно синхронизировали",
  in_package: "Кол-во в таре",
  confirm_action: "Подтвердить действие",
  iiko_deletion_msg:
    "Внимание: Внесение изменений в настройки приведет к удалению всех продуктов iiko. Вы уверены, что хотите продолжить?",
  next: "Следующий",
  export_as: "Экспортировать в",
  total_left_over: "Общий остаток",
  remaining_branches: "Оставшиеся филиалы",
  updated: "Обновлено",
  order_changed: "Последовательность изменена",
  added: "Добавлено",
  are_you_sure_you_want_to_delete: "Вы уверены, что хотите удалить эту запись?",
  you_cannot_undo: "Вы не можете восстановить эту операцию.",
  support: "Поддержка",
  are_you_sure_you_want_to_delete_good:
    "Вы уверены, что хотите удалить эту запись? Она уже использовалась в инвентаризации !",
  inventory_send_message: "Успешно отправлено",
};
