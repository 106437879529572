import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { Container, TextField, Card, Box } from "@material-ui/core";

import {
  useText,
  useGetData,
  usePutData,
  usePostData,
  useGetList,
} from "hooks";
import useStore from "context";

import * as Api from "service";
import { Save, MultiSelect } from "components";
import { useTranslation } from "localization/translation";
import { isValidPhone } from "helpers/formValidation";
import { PHONE_FIELD_NAME, REQUIRED_ERROR_MESSAGE } from "Constants";

const initForm = {
  id: "",
  name: "",
  surname: "",
  phone: "",
  email: "",
  branches: [],
};

const ManagerInvitation = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const [data, , refresh] = useGetData(
    id && Api.managerInvitations.getById,
    id
  );
  const postCallback = usePostData(Api.managerInvitations.create, refresh);
  const updateCallback = usePutData(Api.managerInvitations.update, refresh);
  const { setErrorMsg } = useStore();

  const [text, setText, , input, error, setError] = useText(data || initForm);
  const [branches] = useGetList(Api.branches.getAll);

  const validateField = (field, value) => {
    let isValid = true;
    let errorMessage = "";

    switch (field) {
      case "name":
      case "surname":
      case "email":
        isValid = !!value;
        errorMessage = isValid ? "" : REQUIRED_ERROR_MESSAGE;
        break;
      case "phone":
        if (!value) {
          isValid = false;
          errorMessage = REQUIRED_ERROR_MESSAGE;
        } else if (!isValidPhone(value)) {
          isValid = false;
          errorMessage = "Please enter valid phone number.";
        }
        break;
      case "branches":
        isValid = Array.isArray(value) && value.length > 0;
        errorMessage = isValid ? "" : "Please select at least one branch";
        break;
      default:
        isValid = true;
    }

    setError((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const handleChange = (field, value) => {
    const validPhoneNumber = /^\+?\d*$/;

    const isPhone = field === PHONE_FIELD_NAME;
    const isPhoneValid = isPhone && !validPhoneNumber.test(value);

    let phoneNumberValue = isPhone && value.replace(/[^\d]/g, "");

    if (isPhone && value.charAt(0) === "+") {
      phoneNumberValue = `+${phoneNumberValue}`;
    }

    setText((prevText) => ({
      ...prevText,
      [field]: isPhoneValid ? phoneNumberValue : value,
    }));
    validateField(field, value);
  };

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();
      setError({});

      const errors = {
        name: !text.name,
        surname: !text.surname,
        phone: !text.phone || !isValidPhone(text.phone),
        email: !text.email,
        branches: text.branches.length === 0,
      };

      if (Object.values(errors).some((hasError) => hasError)) {
        setError(errors);
        setErrorMsg("Fields are invalid.");
        return;
      }

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            {...input("name")}
            label={translation.first_name}
            error={!!error?.name}
            helperText={error?.name && REQUIRED_ERROR_MESSAGE}
            onChange={(e) => handleChange("name", e.target.value)}
          />

          <TextField
            {...input("surname")}
            label={translation.last_name}
            error={!!error?.surname}
            helperText={error?.surname && REQUIRED_ERROR_MESSAGE}
            onChange={(e) => handleChange("surname", e.target.value)}
          />

          <TextField
            {...input("phone")}
            label={translation.phone}
            error={!!error?.phone}
            helperText={
              !!error?.phone
                ? typeof error?.phone === "string"
                  ? error.phone
                  : REQUIRED_ERROR_MESSAGE
                : null
            }
            onChange={(e) => handleChange("phone", e.target.value)}
          />

          <TextField
            {...input("email")}
            label={translation.email}
            error={!!error?.email}
            helperText={error?.email && REQUIRED_ERROR_MESSAGE}
            onChange={(e) => handleChange("email", e.target.value)}
          />

          {branches && (
            <MultiSelect
              noCard
              className="w-100"
              value={text.branches}
              onChange={(val) => handleChange("branches", val)}
              data={branches}
              label={translation.select_branch}
              field="name"
              error={!!error?.branches}
            />
          )}
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default ManagerInvitation;
