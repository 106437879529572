import React, { useCallback } from "react";
import {
  Container,
  TextField,
  Card,
  Box,
  Switch,
  FormControlLabel,
} from "@material-ui/core";

import {
  useText,
  useGetData,
  usePutData,
  usePostData,
  useGetList,
} from "../../../hooks";
import useStore from "../../../context";

import * as Api from "../../../service";
import { Save, MultiSelect } from "../../../components";
import { useParams } from "react-router-dom";
import { useTranslation } from "localization/translation";
import { isValidPhone } from "helpers/formValidation";
import { PHONE_FIELD_NAME, REQUIRED_ERROR_MESSAGE } from "Constants";

const initForm = {
  active: true,
  name: "",
  surname: "",
  phone: "",
  email: "",
  branches: [],
};

const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const [data, , refresh] = useGetData(id && Api.barmens.getById, id);
  const postCallback = usePostData(Api.barmens.create, refresh);
  const updateCallback = usePutData(Api.barmens.update, refresh);
  const { setErrorMsg } = useStore();
  const [branches] = useGetList(Api.branches.getAll);

  const [text, setText, , input, error, setError] = useText(data || initForm);

  const validateField = (field, value) => {
    let isValid = true;
    let errorMessage = "";

    switch (field) {
      case "name":
      case "surname":
      case "email":
        isValid = !!value;
        errorMessage = isValid ? "" : REQUIRED_ERROR_MESSAGE;
        break;
      case "phone":
        if (!value) {
          isValid = false;
          errorMessage = REQUIRED_ERROR_MESSAGE;
        } else if (!isValidPhone(value)) {
          isValid = false;
          errorMessage = "Please enter valid phone number.";
        }
        break;
      case "branches":
        isValid = Array.isArray(value) && value.length > 0;
        errorMessage = isValid ? "" : "Please select at least one branch";
        break;
      default:
        isValid = true;
    }

    setError((prevErrors) => ({
      ...prevErrors,
      [field]: errorMessage,
    }));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    const validPhoneNumber = /^\+?\d*$/;

    const isPhone = name === PHONE_FIELD_NAME;
    const isPhoneValid = isPhone && !validPhoneNumber.test(value);

    let phoneNumberValue = value.replace(/[^\d]/g, "");

    if (value.charAt(0) === "+") {
      phoneNumberValue = `+${phoneNumberValue}`;
    }

    setText((prev) => ({
      ...prev,
      [name]: isPhoneValid ? phoneNumberValue : value,
    }));
    validateField(name, value);
  };

  const phoneStatus = (e, text) => {
    const { name, value } = e.target;
    const phoneReg = /^(?:(\+))?(?:[0-9]{0,3}[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{3}\)))?(\d{3})(?:(?!\(\d{3})\))?[\s.\/-]?)?(?:(?:\((?=\d{4}\)))?(\d{4})(?:(?!\(\d{4})\))?[\s.\/-]?)?$/.test(
      value
    );
    if (text) {
      setText((text) => ({
        ...text,
        [name]: phoneReg ? value : value && text[name],
      }));
    }
  };

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const submitForm = useCallback(
    (e) => {
      e.preventDefault();
      setError({}); // Clear previous errors

      const errors = {
        name: !text.name,
        surname: !text.surname,
        phone: !text.phone
          ? true
          : !isValidPhone(text.phone)
          ? "Please enter valid phone number."
          : null,
        email: !validateEmail(text.email),
        branches: text.branches.length === 0,
      };

      if (Object.values(errors).some((hasError) => hasError)) {
        setError(errors);
        setErrorMsg("Please correct the errors in the form.");
        return;
      }

      id ? updateCallback(text) : postCallback(text);
    },
    [id, postCallback, setErrorMsg, text, updateCallback]
  );

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <TextField
            {...input("name")}
            label={translation.first_name}
            error={!!error?.name}
            helperText={error?.name && REQUIRED_ERROR_MESSAGE}
            onChange={handleInputChange}
          />

          <TextField
            {...input("surname")}
            label={translation.last_name}
            error={!!error?.surname}
            helperText={error?.surname && REQUIRED_ERROR_MESSAGE}
            onChange={handleInputChange}
          />

          <TextField
            {...input("phone")}
            label={translation.phone}
            error={!!error?.phone}
            helperText={
              !!error?.phone
                ? typeof error?.phone === "string"
                  ? error.phone
                  : REQUIRED_ERROR_MESSAGE
                : null
            }
            onChange={(e) => {
              phoneStatus(e, text);
              handleInputChange(e);
            }}
          />

          <TextField
            {...input("email")}
            label={translation.email}
            disabled={id}
            error={!!error?.email}
            helperText={error?.email && "Invalid email address"}
            onChange={handleInputChange}
          />
          <div className="my-3 py-3">
            <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Switch
                  checked={text.active}
                  onClick={() =>
                    setText((st) => ({ ...st, active: !st.active }))
                  }
                  className="switch-medium"
                  color="primary"
                  name="checkedB"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              }
              label={
                <span style={{ marginLeft: "10px" }}>user active state</span>
              }
            />
          </div>
          {branches && (
            <>
              <MultiSelect
                noCard
                className="w-100"
                value={text.branches}
                onChange={(val) => {
                  setText((st) => ({ ...st, branches: val }));
                  validateField("branches", val);
                }}
                data={branches}
                label={translation.select_branch}
                field="name"
                error={!!error?.branches}
                helperText={
                  error?.branches && "Please select at least one branch"
                }
              />
            </>
          )}
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
