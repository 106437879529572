import React, { useCallback, useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  Container,
  TextField,
  Card,
  Box,
  InputAdornment,
} from "@material-ui/core";
import { AutorenewTwoTone } from "@material-ui/icons";

import {
  useText,
  useGetData,
  usePutData,
  useGetList,
  usePostData,
} from "hooks";
import useStore from "context";
import * as Api from "service";

import { Lang, Save, Select } from "components";
import { calculate } from "helpers/calculateGoods";
import Gallery from "components/gallery";
import { useTranslation } from "localization/translation";

import {
  ERROR_MESSAGE,
  MANAGER_ROLE,
  VOLUME_ERROR,
  WEIGHT_ERROR,
} from "../constants";
import {
  GRAM,
  KILOGRAM,
  LITER,
  MAX_KILOGRAM_LITER_WEIGHT,
  MEASUREMENT_OPTIONS_TEMPORARY,
  MILLILITER,
  MILLILITERS_VALUE,
  REQUIRED_ERROR_MESSAGE,
} from "Constants";
import {
  changeVolumeValueToMilliliters,
  changeWeightValueToGrams,
} from "helpers/getMeasurementName";
import { getNumericNumbers } from "helpers/formHelpers";

const initForm = {
  name_en: "",
  name_hy: "",
  name_ru: "",
  goods_type_id: "",
  weight_full: "",
  weight_empty: "",
  barcode: "",
  litrage: "",
  image: "",
  measurement_unit: "",
};
const query = { limit: 0 };
const Form = () => {
  const { id } = useParams();
  const translation = useTranslation();
  const {
    setErrorMsg,
    lang,
    user,
    language,
    weightValue,
    volumeValue,
  } = useStore();
  const [newFiles, setNewFiles] = useState(null);
  const [labelName, setLabelName] = useState(translation.details);
  const [density, setDensity] = useState();
  const [isDisable, setIsDisable] = useState(false);
  const [errors, setErrors] = useState([]);

  const isGram = weightValue === GRAM;
  const isMilliliter = volumeValue === MILLILITER;

  const apiCallTypes =
    user.role === MANAGER_ROLE ? Api.goodsTypeManager : Api.goodsType;
  const apiCall = user.role === MANAGER_ROLE ? Api.goodsManager : Api.goods;

  const [types] = useGetList(apiCallTypes.getAll, query);
  const [data, , refresh] = useGetData(id && apiCall.getById, id);
  const postCallback = usePostData(apiCall.create, refresh);
  const updateCallback = usePutData(apiCall.update, refresh);

  const [text, setText, , input, error, setError] = useText(
    data || initForm,
    true
  );

  const validateField = (field, value) => {
    if (!value) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: true,
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [field]: false,
      }));
    }
  };

  const submitForm = useCallback(
    async (e) => {
      e.preventDefault();

      const fieldsErrors = {
        name_en: !text.name_en,
        name_hy: !text.name_hy,
        name_ru: !text.name_ru,
        goods_type_id: !text.goods_type_id,
        weight_full:
          text.measurement_unit === MILLILITERS_VALUE
            ? !text.weight_full
            : false,
        weight_empty:
          text.measurement_unit === MILLILITERS_VALUE
            ? !text.weight_empty
            : false,
        litrage:
          text.measurement_unit === MILLILITERS_VALUE ? !text?.litrage : false,
        measurement_unit: !text.measurement_unit,
      };

      if (Object.values(fieldsErrors).some((hasError) => hasError)) {
        setError(fieldsErrors);
        setErrorMsg(ERROR_MESSAGE);
        return;
      }

      if (["en"].some((el) => !text[`name_${el}`]) || !text.measurement_unit)
        return setErrorMsg(ERROR_MESSAGE);

      if (!text.litrage) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          litrage: true,
        }));
      }

      const formData = new FormData();
      if (newFiles) {
        formData.append("file", newFiles[0]);
      }

      const full = +text.weight_full;
      const empty = +text.weight_empty;
      if (full <= empty && text.measurement_unit === MILLILITERS_VALUE)
        return setErrorMsg(translation.weight_empty_bigger_full);

      if (
        weightValue === KILOGRAM &&
        text.measurement_unit === MILLILITERS_VALUE &&
        (text.weight_full > MAX_KILOGRAM_LITER_WEIGHT ||
          text.weight_empty > MAX_KILOGRAM_LITER_WEIGHT)
      ) {
        return setErrorMsg(WEIGHT_ERROR);
      }

      if (
        volumeValue === LITER &&
        text.measurement_unit === MILLILITERS_VALUE &&
        text.litrage > MAX_KILOGRAM_LITER_WEIGHT
      ) {
        return setErrorMsg(VOLUME_ERROR);
      }

      const link = newFiles
        ? await Api.main.uploadFile(formData).then((res) => res.filePath)
        : text.image;

      if (text.measurement_unit === MILLILITERS_VALUE) {
        text.weight_full = changeWeightValueToGrams(
          parseFloat(text.weight_full),
          weightValue
        );
        text.weight_empty = changeWeightValueToGrams(
          parseFloat(text.weight_empty),
          weightValue
        );
        text.density = parseFloat(text.density);
        text.litrage = changeVolumeValueToMilliliters(
          parseFloat(text.litrage),
          volumeValue
        );
      }

      const data = {
        ...text,
        image: link,
      };

      id ? updateCallback(data) : postCallback(data);
    },
    [
      text,
      setErrorMsg,
      newFiles,
      weightValue,
      volumeValue,
      id,
      updateCallback,
      postCallback,
    ]
  );

  const handleSetFullWeight = () => {
    const full = calculate.fullWeight(
      text.weight_empty,
      text.litrage,
      density,
      isGram,
      isMilliliter
    );
    setText((st) => ({ ...st, weight_full: full }));
  };

  const handleChangeMeasurement = (id) => {
    if (data && id === MILLILITERS_VALUE) {
      setText((st) => ({ ...st, measurement_unit: id }));
    } else {
      setText((st) => ({
        ...st,
        measurement_unit: id,
        weight_full: null,
        weight_empty: null,
        litrage: null,
      }));
    }
  };

  const handleSetEmptyWeight = () => {
    const empty = calculate.emptyWeight(
      text.weight_full,
      text.litrage,
      density,
      isGram,
      isMilliliter
    );
    setText((st) => ({ ...st, weight_empty: empty }));
  };

  const handleChangeGoodType = (id) => {
    const findGood = types.find((elem) => elem.id === id);
    const averageDensity = Number(findGood?.average_density);
    setDensity(averageDensity);
    setText((st) => ({ ...st, goods_type_id: id }));
  };

  const handlePositiveNumberInputChange = (e) => {
    const { name, value } = e.target;

    const sanitizedValue = getNumericNumbers(value);

    setText((prevText) => ({
      ...prevText,
      [name]: sanitizedValue,
    }));
  };

  const handleChangeWithoutSpaces = (e) => {
    const { name, value } = e.target;

    const sanitizedValue = value.replace(/\s/g, "");

    setText((prevText) => ({
      ...prevText,
      [name]: sanitizedValue,
    }));
  };

  useEffect(() => {
    if (data && types) {
      const findGood = types.find((elem) => elem.id === data.goods_type_id);
      const averageDensity = Number(findGood?.average_density);
      setDensity(averageDensity);
    }
  }, [data, types]);

  useEffect(() => {
    const equal = calculate.isEqual(
      text.weight_full,
      text.weight_empty,
      text.litrage,
      density,
      isGram,
      isMilliliter
    );
    setIsDisable(equal);
  }, [text, density, weightValue, volumeValue, isGram, isMilliliter]);

  return (
    <Container>
      <Card className="shadow-xxl px-4 py-2">
        <Box component="form" onSubmit={submitForm}>
          <Lang />
          <Gallery
            single
            label={labelName}
            setNewFile={setNewFiles}
            value={text.image ? text.image : null}
            setImages={(img) => setText((st) => ({ ...st, image: img }))}
            onChange={() => setText((st) => ({ ...st, image: "" }))}
            setLabelName={setLabelName}
          />
          <TextField
            {...input(`name_${lang}`)}
            label={translation.title}
            error={!!error?.[`name_${lang}`]}
            helperText={error?.[`name_${lang}`] && REQUIRED_ERROR_MESSAGE}
          />
          {types && (
            <Select
              onChange={(val) => handleChangeGoodType(val)}
              value={text.goods_type_id}
              field={`name_${language}`}
              density="average_density"
              label={translation.goods_type}
              noCard
              options={types}
              error={!!error?.goods_type_id}
            />
          )}
          <TextField
            {...input(`barcode`)}
            onChange={handleChangeWithoutSpaces}
            label={translation.barcode}
          />
          <Select
            onChange={(val) => handleChangeMeasurement(val)}
            value={text.measurement_unit}
            isMeasurementSelect
            field={`name_${language}`}
            label={translation.measurement}
            disabled={!!data}
            noCard
            options={MEASUREMENT_OPTIONS_TEMPORARY}
            error={!!error?.measurement_unit}
            helperText={error?.measurement_unit && REQUIRED_ERROR_MESSAGE}
          />
          {text.measurement_unit === MILLILITERS_VALUE && (
            <>
              <TextField
                {...input(`weight_full`)}
                onChange={handlePositiveNumberInputChange}
                type="text"
                label={translation[`full_bottle_weight_${weightValue}`]}
                error={!!error?.weight_full}
                helperText={error?.weight_full && REQUIRED_ERROR_MESSAGE}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <button
                        className="unstyle_button"
                        type="button"
                        disabled={
                          isDisable ||
                          (text?.weight_empty && text?.litrage && !!density
                            ? false
                            : true)
                        }
                        onClick={handleSetFullWeight}
                      >
                        <AutorenewTwoTone />
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                {...input(`weight_empty`)}
                onChange={handlePositiveNumberInputChange}
                type="text"
                label={translation[`empty_bottle_weight_${weightValue}`]}
                error={!!error?.weight_empty}
                helperText={error?.weight_empty && REQUIRED_ERROR_MESSAGE}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <button
                        className="unstyle_button"
                        type="button"
                        disabled={
                          isDisable ||
                          (text?.weight_full && text?.litrage && !!density
                            ? false
                            : true)
                        }
                        onClick={handleSetEmptyWeight}
                      >
                        <AutorenewTwoTone />
                      </button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                {...input(`litrage`)}
                label={translation[`volume_${volumeValue}`]}
                error={!!error.litrage}
                helperText={error.litrage && REQUIRED_ERROR_MESSAGE}
                onChange={(e) => {
                  handlePositiveNumberInputChange(e);
                }}
              />
            </>
          )}
          <Save />
        </Box>
      </Card>
    </Container>
  );
};

export default Form;
